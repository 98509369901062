import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { fetchData, GET_URL, ADD_USER_SUBSCRIBE } from '../components/Service'
import { validateForm, initialFormState, zohoChat } from "../components/Helper"
import {Context} from "../components/Context"


function FooterFront() {
    const [context] = useContext(Context);
    const user_group_id = context && context.auth && context.auth.user_group_id;
    const opacity = user_group_id === 1 || user_group_id === 3 ? 0 : 1 ;
    const [blogs, setBlogs] = useState('#');
    const [cases, setCases] = useState('#');
    const [tinyloader, setTinyloader] = useState(false);

    useEffect(()=>{
        fetchData(`${GET_URL}?type=blogs`, 'GET', '', true, false, (res) => { if (res.records) {setBlogs(res.records)}})
        fetchData(`${GET_URL}?type=cases`, 'GET', '', true, false, (res) => { if (res.records) { setCases(res.records)}})
    },[opacity])

    const submitSubscribe = (e) => {
        var business_name = document.querySelector('#business_names').value;
        let formData = new FormData(document.getElementById('subscribeForm'));
        formData.append('business_name', business_name);
        if (validateForm(e, 'subscribeForm')) {
            setTinyloader(true)
            fetchData(ADD_USER_SUBSCRIBE, 'POST', formData, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('subscribeForm')
                }
            })
        }
    }

    const redirectTo = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank');
    }

    return (
        <>
        <style>{`
            .zsiq_theme1.zsiq_floatmain {opacity: ${opacity} !important; pointer-events :all; display:block !important;}
            .siqanim{
                transform: scale(1)!important;
            }
        `}
        </style>
        { user_group_id === ''? zohoChat() : ''}
            <span className="go-to-top" title="Go to Top"></span>
            <footer>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-6 col-lg-3">
                            <h5>TERMS</h5>
                            <ul className="footer-links">
                                <li><Link to="terms-conditions">Terms & Conditions</Link></li>
                                <li><Link to="privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="cookie-policy">Cookie Policy</Link></li>
                            </ul>
                            <h5>GET IN TOUCH</h5>
                            <address>
                                <p>1303 W Walnut Hill Ln, Ste 360 Irving TX 75038. USA</p>
                                <a href="mailto:info@amazio.com">
                                    <i className="bi bi-envelope-fill bi-1p2 me-1"></i> info@amazio.com
                                </a>
                                <br />
                                <a href="tel:9723624700">
                                    <i className="bi bi-telephone-fill me-1"></i> 972-362-4700
                                </a>
                            </address>
                        </div>
                        <div className="col-6 col-lg-2">
                            <h5>SITEMAP</h5>
                            <ul className="footer-links">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="services">Services</Link></li>
                                <li><Link to="industries">Industries</Link></li>
                                <li><Link to="plans-pricing">Plans &amp; Pricing</Link></li>
                                <li><Link to="knowledge-base">Knowledge Base</Link></li>
                                <li><Link to="about-us">About Us</Link></li>
                                <li><a href='/blogs' target="_blank" rel="noreferrer" onClick={(e) => redirectTo(e, blogs)}>Blogs</a></li>
                                <li><a href='case-study' target="_blank" rel="noreferrer" onClick={(e) => redirectTo(e, cases)}>Case Studies</a></li>
                                <li><Link to="onboarding-form">Onboarding Form</Link></li>
                                <li><Link to="contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <h5>SERVICES</h5>
                            <ul className="footer-links">
                                <li><Link to="services">All Services</Link></li>
                                <li><Link to="/business-listing-management-usa">Business Digital Listing &amp; Branding</Link></li>
                                <li><Link to="reviews-reputation-sentiment-analysis">Reviews, Reputation &amp; Sentiment Analysis</Link></li>
                                <li><Link to="social-media-management">Social Media Management</Link></li>
                                <li><Link to="digital-content-imaging-services">Digital Content &amp; Imaging Services</Link></li>
                                <li><Link to="competitive-intelligence-insights-analytics">Competitive Intelligence, Insights &amp; Analytics</Link></li>
                                <li><Link to="web-mobile-informational-tags">Web &amp; Mobile Informational Tags</Link></li>
                                <li><Link to="web-app-development-maintenance">Web &amp; App Development with Maintenance</Link></li>
                                <li><Link to="voice-search-pro">Voice Search Pro</Link></li>
                                <li><Link to="print-media">Print Media</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <p>Stay updated with the latest in Digital Marketing and Technology.</p>
                            <form id="subscribeForm" className="needs-validation" method="post" noValidate>
                                <div className="mb-2">
                                    <input type="text" name="business_names" id="business_names" className="form-control" placeholder="Name" required />
                                    <div className="invalid-feedback">The name field is required</div>
                                </div>
                                <div className="mb-2">
                                    <input type="email" name="business_email" id="business_email" className="form-control" placeholder="Email" required />
                                    <div className="invalid-feedback">The email field is required</div>
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-warning fw-bold px-3" onClick={(e) => submitSubscribe(e)} disabled={tinyloader} id="submit-contact">
                                        {
                                            !tinyloader ? ' Subscribe '
                                                :
                                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                        }
                                    </button>
                                </div>
                            </form>
                            <hr className="my-4" />
                            <ul className="list-inline social-links text-center text-md-end">
                                <li className="list-inline-item">
                                    <a href="https://www.facebook.com/wowamazio/" rel="noopener noreferrer" target="_blank" title="Facebook" className="icon-facebook">
                                        <i className="bi bi-facebook bi-1p2"></i>
                                    </a>
                                    <a href="https://x.com/Amazio_official" rel="noopener noreferrer" target="_blank" title="Twitter" className="icon-twitter">
                                        <i className="bi bi-twitter bi-1p2"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/amazio-us/" rel="noopener noreferrer" target="_blank" title="Linkedin" className="icon-linkedin">
                                        <i className="bi bi-linkedin bi-1p2"></i>
                                    </a>
                                    <a href="https://www.instagram.com/amazio_official/" rel="noopener noreferrer" target="_blank" title="Instagram" className="icon-instagram">
                                        <i className="bi bi-instagram bi-1p2"></i>
                                    </a>
                                    <a href="https://www.youtube.com/@amazioinformation2527" rel="noopener noreferrer" target="_blank" title="Youtube" className="icon-pinterest">
                                        <i className="bi bi-youtube bi-1p2"></i>
                                    </a>
                                    <a href="https://www.pinterest.com/wowamazio/" rel="noopener noreferrer" target="_blank" title="Pinterest" className="icon-pinterest">
                                        <i className="bi bi-pinterest bi-1p2"></i>
                                    </a>
                                    <a href="https://www.google.com/maps/place/Amazio/@32.8855327,-96.9709239,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xf48e09944b270eb2!8m2!3d32.8855327!4d-96.9687352" rel="noopener noreferrer" target="_blank" title="Google" className="icon-google">
                                        <i className="bi bi-google bi-1p1"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="copyrights">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 py-3 text-center text-sm-start">
                                <p><i className="bi bi-c-circle"></i> {new Date().getFullYear()} AMAZIO. All rights reserved.</p>
                            </div>
                            <div className="col-sm-6 py-3 text-center text-sm-end">
                                <p>Powered by Amazio.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterFront